import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import $ from 'jquery'

export default {
  name: 'VideoPlayer',
  props: {
    showPodcastPlayer: {
      type: Boolean,
      default: false
    },
    isBannerVideo: {
      type: Boolean,
      default: false
    },
    audioSrc: String,
    videoSrc: String,
    timeSpent: Number,
    subtitleUrl: String,
    thumbnailUrl: String,
    contentData: {
      type: Object
    }
  },
  data () {
    return {
      player: null,
      contentId: 0,
      apiCallInterval: null,
      playerOptions: {
        controls: true,
        playbackRates: [0.5, 1, 1.5, 2],
        fill: true,
        controlBar: {
          volumePanel: { inline: false },
          // fullscreenToggle: false,
          children: [
            'playToggle',
            'volumePanel',
            'currentTimeDisplay',
            'timeDivider',
            'durationDisplay',
            'progressControl',
            'liveDisplay',
            'remainingTimeDisplay',
            'customControlSpacer',
            'playbackRateMenuButton',
            'chaptersButton',
            'descriptionsButton',
            'subsCapsButton',
            'audioTrackButton',
            'chromeCastButton',
            'QualitySelector',
            'fullscreenToggle'
          ]
        }
      },
      bannerOptions: {
        autoplay: true,
        muted: true,
        loop: true
      },
      contentState: {
        // * All attributes are optional
        timeViewed: 0
      },
      prevPlaybackPosition: null,
      heartbeatPlayBackPosition: null,
      analyticsState: {
        loggedUser: null,
        page: 'LOGIN',
        type: '',
        action: 'open',
        name: null,
        contentId: null,
        category: null,
        publisherId: null,
        publisherName: null,
        rating: 0,
        review: null,
        bookmark: false,
        like: false,
        searchKeyword: null,
        userId: null, // Influencer userId
        follow: false,
        speedRate: null,
        completed: false,
        windowFocus: false,
        fullscreen: false,
        prevPlaybackPosition: null,
        playbackPosition: null,
        totalDuration: null,
        noteId: null,
        playlistId: null
      }
    }
  },
  methods: {
    ...mapActions([
      'UPDATE_USER_CONTENT_STATE',
      'RESET_STATE'
    ]),
    ...mapMutations([
      'setLoggedUser',
      'setPage',
      'setType',
      'setAction',
      'setName',
      'setContentId',
      'setCategory',
      'setPublisherId',
      'setPublisherName',
      'setRating',
      'setReview',
      'setLike',
      'setBookmark',
      'setTopic',
      'setLevel',
      'setWindowFocus',
      'setCompleted',
      'setSpeedRate',
      'setFullscreen',
      'setPrevPlaybackPosition',
      'setPlaybackPosition',
      'setTotalDuration'

    ]),
    disableRightClick () {
      const myPlayer = document.getElementById('videoPlayer')
      if (myPlayer.addEventListener) {
        myPlayer.addEventListener('contextmenu', function (e) {
          e.preventDefault()
        }, false)
      } else {
        myPlayer.attachEvent('oncontextmenu', function () {
          window.event.returnValue = false
        })
      }
    },
    updateCurrentViewingTime () {
      this.contentState.timeViewed = this.player.currentTime()
      this.updateUserContentState()
    },
    isVideoCompleted () {
      let remainingTime = Math.floor(this.player.duration() - this.player.currentTime())
      return remainingTime <= 10
    },
    setupBanner () {
      $('video').css({
        width: 100 + '%',
        position: 'relative',
        top: 20 + 'px',
        'background-color': 'white'
      })
      $('.video_player').css({
        'margin-bottom': 5 + '%'
      })
      this.player = videojs(this.$refs.videoPlayer, this.bannerOptions, function onPlayerReady () {
        $('video').css({
          width: 100 + '%',
          height: 'auto',
          position: 'relative',
          top: 20 + 'px'
        })
      })
    },
    bombIsAway (eventType, action) {
      if (!this.isBannerVideo) {
        console.log(action)
        this.setAction(action)
        if (action === 'completed') {
          this.setCompleted(true)
        }
        this.setPlaybackPosition(this.player.currentTime())
        if (action === 'heart_beat') {
          this.setPrevPlaybackPosition(this.heartbeatPlayBackPosition)
          this.heartbeatPlayBackPosition = this.analyticsState.playbackPosition
        }
        this.setSpeedRate(this.player.playbackRate())
        this.setFullscreen(this.player.isFullscreen())
        this.setWindowFocus(this.player.userActive())
        console.log(this.getAnalyticsState)
        // eslint-disable-next-line no-undef
        divolte.signal(eventType, this.getAnalyticsState)
      }
    },
    setupContentPlayer () {
      let timeSpent = this.timeSpent
      this.contentId = this.$route.params.contentId
      $('.video_player').css({
        height: '72vh',
        width: 100 + '%'
      })
      if (this.showPodcastPlayer) {
        $('video').css({
          'object-fit': 'scale-down'
        })
      }
      this.player = videojs(this.$refs.videoPlayer, this.playerOptions, function onPlayerReady () {
        this.hlsQualitySelector({
          displayCurrentQuality: true
        })
        this.fill(true)
        this.currentTime(timeSpent)
      })
      this.apiCallInterval = setInterval(() => this.updateCurrentViewingTime(), 10000)
      this.player.on('seeked', () => {
        this.updateCurrentViewingTime()
      })
      this.player.on('fullscreenchange', this.fullScreenControl)
    },
    updateUserContentState () {
      this.UPDATE_USER_CONTENT_STATE({
        contentId: this.contentId,
        payload: this.contentState
      })
    },
    setAnalyticsContentData (dataObject) {
      if (!this.isBannerVideo) {
        this.setType(dataObject.content.contentType)
        this.setPage('VIDEO_PAGE')
        this.setName(dataObject.content.title)
        this.setContentId(dataObject.content.id)
        this.setCategory(dataObject.content.category.name)
        this.setPublisherId(dataObject.content.publisher.id)
        this.setPublisherName(dataObject.content.publisher.name)
        this.setRating(dataObject.rating)
        if (dataObject.review) {
          this.setReview(dataObject.review)
        }
        this.setBookmark(dataObject.isSaved)
        this.setLike(dataObject.liked !== 0)
        this.setCompleted(dataObject.isCompleted)
        this.setPrevPlaybackPosition(dataObject.timeViewed)
        this.setPlaybackPosition(dataObject.timeViewed)
        this.setTotalDuration(dataObject.content.timeTotal)
      }
    },
    muteControl () {
      if (this.player.muted()) {
        this.bombIsAway('videoEvent', 'mute')
      } else {
        this.bombIsAway('videoEvent', 'unmute')
      }
    },
    // :TODO Integrate TimeViewed APi Here
    sendHeartBeat () {
      this.bombIsAway('videoEvent', 'heart_beat')
    },
    fullScreenControl () {
      if (this.player.isFullscreen()) {
        this.bombIsAway('videoEvent', 'full_screen')
      } else {
        this.bombIsAway('videoEvent', 'full_screen_undo')
      }
    },
    seekingVideo () {
      this.setPrevPlaybackPosition(this.prevPlaybackPosition)
      this.setPlaybackPosition(this.player.currentTime())
      this.prevPlaybackPosition = this.getAnalyticsState.playbackPosition // getter
      if (this.getAnalyticsState.prevPlaybackPosition <= this.getAnalyticsState.playbackPosition) {
        this.bombIsAway('videoEvent', 'skip_forward')
      } else {
        this.bombIsAway('videoEvent', 'skip_backward')
      }
    }
  },
  computed: {
    ...mapGetters([
      'getAnalyticsState'
    ])
  },
  mounted () {
    require('videojs-hls-quality-selector')
    require('videojs-contrib-quality-levels')

    this.contentId = this.$route.params.contentId
    this.disableRightClick()
    this.isBannerVideo ? this.setupBanner() : this.setupContentPlayer()
    if (!this.isBannerVideo) {
      this.prevPlaybackPosition = this.contentData.timeViewed
      this.heartbeatPlayBackPosition = this.contentData.timeViewed
      this.setAnalyticsContentData(this.contentData)
      $('.vjs-mute-control').click(this.muteControl)
      this.heartBeatInterval = setInterval(() => this.sendHeartBeat(), 10000)
    }
  },
  beforeDestroy () {
    if (!this.isBannerVideo) {
      clearInterval(this.apiCallInterval)
      this.updateCurrentViewingTime()
      clearInterval(this.heartBeatInterval)
    }
    if (this.player) {
      this.player.dispose()
    }
  }
}
