import InfluencerCarousal from '@/components/InfluencerCarousal/index.vue'
import CourseCarousal from '@/components/CourseCarousal/index.vue'
import VideoPlayer from '@/components/socialvue/videoplayer/VideoPlayer.vue'
import Chat from '../Chat/Index'
import firebase from 'firebase'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Influencers',
  components: { InfluencerCarousal, CourseCarousal, VideoPlayer, Chat },
  data () {
    return {
      influencer_banner_url: '',
      influencersArray: [],
      currentActiveIndex: 0,
      palylist: '\'s Playlist',
      row1: {
        mainHeading: 'ZOOMIFEED',
        feedHeading: 'People you should be following'
      },
      row2: {
        mainHeading: 'DATA DRIVEN',
        feedHeading: 'Most Viewed'
      },
      row3: {
        mainHeading: 'DATA DRIVEN',
        feedHeading: 'Most Liked'
      },
      row10: {
        mainHeading: 'SOCIAL ENGINE',
        feedHeading: 'Scott O’Neil’s Playlist'
      },
      row4: {
        mainHeading: 'DATA DRIVEN',
        feedHeading: 'Most followed'
      },
      row6: {
        mainHeading: 'SOCIAL ENGINE',
        feedHeading: 'Michelle Obama’s Playlist'
      },
      row7: {
        mainHeading: 'DATA DRIVEN',
        feedHeading: 'Movers & Shakers'
      },
      row8: {
        mainHeading: 'SOCIAL ENGINE',
        feedHeading: 'Somebody Cool’s Playlist'
      },
      cardColorBlack: {
        'bgColor': 'black',
        'fontColor': 'white',
        'mainColor': 'black',
        'feedColor': 'black'
      },
      cardColorWhite: {
        'bgColor': 'white',
        'fontColor': 'black',
        'mainColor': 'red',
        'feedColor': 'white'
      },
      ifeed1Array: [],
      iffeed2Array: [],
      iffeed3Array: [],
      iffeed4Array: [],
      iffeed5Array: [],
      iffeed6Array: []
    }
  },
  created () {
    const remoteConfig = firebase.remoteConfig()
    remoteConfig.fetchAndActivate()
      .then(() => {
        const val = remoteConfig.getValue('influencer_banner_url')
        this.influencer_banner_url = JSON.parse(val._value).url
      })
      .catch(() => {
      })
  },
  methods: {
    ...mapActions([
      'RESET_STATE',
      'GET_INFLUENCER_LIKED',
      'GET_INFLUENCER_MOST_VIEWED',
      'GET_INFLUENCERS_PLAYLIST',
      'GET_INFLUENCER_MOVERS_SHAKERS',
      'GET_INFLUENCER_MOST_FOLLOWED'
    ]),
    ...mapMutations([
      'setLoggedUser',
      'setPage'
    ])
  },
  computed: {
    ...mapGetters([
      'getInfluencersPlaylist',
      'getInfluencerMostFollowed',
      'getInfluencerMostLiked',
      'getAnalyticsState',
      'getInfluencerMoversAndShakers',
      'getInfluencerMostViewed'
    ])

  },
  mounted () {
    this.RESET_STATE()
    console.log('On INFLUENCER_PAGE')
    this.setPage('INFLUENCER_PAGE')
    // eslint-disable-next-line no-undef
    divolte.signal('pageView', this.getAnalyticsState)

    this.GET_INFLUENCER_MOST_VIEWED()
    this.GET_INFLUENCER_MOVERS_SHAKERS()
    this.GET_INFLUENCER_MOST_FOLLOWED()
    this.GET_INFLUENCER_LIKED()
    this.GET_INFLUENCERS_PLAYLIST({
      params: {
        'random': true
      }
    })
  }

}
