import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'InfluencerCard',
  props: {
    influencerData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      influencerName: '',
      influencerDesignation: '',
      areaOfExpertize: '',
      likes: '',
      profile_image_url: ''

    }
  },
  created () {
    this.profile_image_url = this.influencerData.profileUrl
    this.influencerName = this.influencerData.name
    this.influencerDesignation = this.influencerData.jobTitle
    this.likes = this.influencerData.likes
    this.areaOfExpertize = this.influencerData.description
  },
  methods: {
    ...mapActions([
      'RESET_STATE',
      'GET_INTERACTIONS'

    ]),
    ...mapMutations([
      'setType',
      'setAction',
      'setName',
      'setLike',
      'setUserId',
      'setFollow'

    ]),
    goToProfile () {
      this.bombIsAway('userCardClick', 'open')
      let param = this.influencerName.split(' ')[0]
      param = param.toLowerCase()
      this.$router.push({ path: `/influencers/${this.influencerData.userId}/${param}` })
    },
    bombIsAway (eventType, action) {
      console.log(eventType + ' ' + action)
      this.setType('USER')
      this.setAction(action)
      this.setName(this.influencerData.name)
      this.setLike(this.getInfluencerInteraction.like)
      this.setFollow(this.getInfluencerInteraction.follow)
      this.setUserId(this.influencerData.userId)

      console.log(this.getAnalyticsState)
      // eslint-disable-next-line no-undef
      divolte.signal(eventType, this.getAnalyticsState)
    }
  },
  mounted () {
    this.GET_INTERACTIONS({
      params: {
        'followingId': this.influencerData.userId
      }
    })
  },
  computed: {
    ...mapGetters([
      'getAnalyticsState',
      'getInfluencerInteraction'
    ])
  }
}
