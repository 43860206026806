import Flickity from 'vue-flickity'
import InfluencerCard from '@/components/InfluencerCard/index.vue'

export default {
  name: 'InfluencerCarousal',
  props: {
    cardData: {
      type: Array
    },
    mainHeading: {
      type: String
    },
    feedHeading: {
      type: String,
      required: true
    }
  },
  components: { Flickity, InfluencerCard
  },
  data () {
    return {
      flickityOptions: {
        groupCells: true,
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: false,
        contain: true,
        draggable: false

        // any options from Flickity can be used
      },
      currentActiveIndex: 0

    }
  },
  created () {
  },
  mounted () {
    // setTimeout(() => {
    //   this.$refs.flickity.on('change', index => {
    //     this.currentActiveIndex = index
    //   })
    // }, 300)
  },
  methods: {
    showPrevElement () {
      this.$refs.flickity.previous()
    },
    showNextElement () {
      this.$refs.flickity.next()
    }
  },
  computed: {
  }
}
